import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Button } from "@e-seikatsu/design-system";
import { DefaultProgress } from "esa-components";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import CommonErrorPanel from "components/ErrorPanel/ErrorPanel";

const RequiredAuthentication = withAuthenticationRequired(
  (): JSX.Element => <Outlet />,
  {
    onRedirecting: (): JSX.Element => <DefaultProgress />,
  },
);

const RequireAuth0Login = (): JSX.Element => {
  const { isLoading, error, logout } = useAuth0();

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error("Error occurred on RequireAuth0Login", { error });
    }
  }, [error]);

  if (isLoading) {
    return <DefaultProgress />;
  }

  if (error) {
    return (
      <CommonErrorPanel
        heading="ログインに失敗しました"
        subHeading="無効なユーザであるためログインできませんでした。アカウント管理者にご確認ください。"
        action={
          <Button
            type="button"
            variant="contained"
            onClick={(): Promise<void> =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
          >
            ログイン画面に戻る
          </Button>
        }
      />
    );
  }

  return <RequiredAuthentication />;
};

export default RequireAuth0Login;
